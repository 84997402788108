import React from 'react';
import "./Login.css";

import AzureADButton from "./AzureADButton";

const Login = () => {

return (
  <div className="dashboard-login">
    <div className="overlap-wrapper">
      <div className="overlap">
        <div className="overlap-group">
          <div className="ellipse" />
          <div className="div" />
        </div>
      </div>
      <div className="form">
        <AzureADButton />
      </div>
    </div>
  </div>
  );
};

export default Login;
