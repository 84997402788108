/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const errorMessageStyles = css`
    display: flex;
    align-items: center;
    color: rgb(192, 190, 255);
    font-size: 14px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 5px;
`;

const ErrorMessage = ({message}) => (
    <div css={errorMessageStyles} >
        {message}
    </div>
);

export default ErrorMessage;

