import { LogLevel } from '@azure/msal-browser';

export const msalConfig = {
    auth: {
      // clientId: 'a86cb239-d023-4cd5-8fe7-4b7e9a2bdfbb',
      clientId: 'a5038015-e035-4b1c-a088-cb254dad60e8',
      authority: 'https://login.microsoftonline.com/b5f3202f-73b3-40a8-a61e-596ab18836ea',
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            default:
              return;
          }
        },
        logLevel: LogLevel.Verbose,
      },
    },
  };
  
// https://login.microsoftonline.com/b5f3202f-73b3-40a8-a61e-596ab18836ea/oauth2/v2.0/authorize?
// client_id=a86cb239-d023-4cd5-8fe7-4b7e9a2bdfbb&
// response_type=code&
// redirect_uri=https://kirillsite.s3.eu-north-1.amazonaws.com/index.html&
// response_mode=query&
// scope=openid%20profile%20email