import React, { useState } from 'react';
import PropTypes from 'prop-types'
import styles from './ModalShutdownConfirmation.module.css';
import Button from '../Project/ProjectComponents/Button';

const ModalShutdownConfirmation = ({ isOpen, onClose, onAccept}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = (e) => {
    onClose(e);
  }
  
  const handleAccept = async (e) => {
    e.preventDefault();
    setIsDisabled(true);
    setIsLoading(true);
    await onAccept();
    setIsDisabled(false);
    setIsLoading(false);
  };

  
  return (
    <>
    {isOpen && (
    <div className={styles.coverPage}>
        <div className={styles.myStyleModalAll} >
            <div className={styles.myStyleModalTitle}>
                Are you sure?
            </div>
            <br />
            <div className={styles.myStyleModalText}>
                You won't be able to revert this.
            </div>
            <br />
            <div className={styles.buttonContainer}>
                <Button type={isDisabled ? "secondary" : "primary"} children="Cancel" onClick={closeModal} disabled={isDisabled} />
                <div style={{display: 'flex'}}>
                  {isLoading ? <div className={styles.loader}></div> : ''}
                  <Button type={isDisabled ? "secondary" : "primary"} children="Shutdown" onClick={handleAccept} disabled={isDisabled} />
                </div>
            </div>
        </div>
    </div>
    )}
    </>
  );
};

ModalShutdownConfirmation.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired
};

export default ModalShutdownConfirmation;