import React from 'react';
import styles from './Navbar.module.css';
import { useMsal, useAccount } from '@azure/msal-react';

const Navbar = () => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const handleLogout = (event) => {
        event.preventDefault();
        instance.logout();
    };
    return (
        <nav className={styles.navbar}>
            <div className={styles.logo}>
                {/* <img src="path_to_logo_image" alt="Logo" /> */}
            </div>
            <ul className={styles.navLinks}>
                {/* <li><a href="#home">Home</a></li>
                <li><a href="#features">Features</a></li>
                <li><a href="#pricing">Pricing</a></li>
                <li><a href="#faqs">FAQs</a></li>
                <li><a href="#about">About</a></li> */}
            </ul>
            <div className={styles.search}>
                {/* <input type="text" placeholder="Search..." /> */}
            </div>
            <div className={styles.buttons}>
                <button className={styles.login} onClick={handleLogout}>Logout</button>
                <span className={styles.user}>{account ? account.username : 'Guest'}</span>
                {/* <button className={styles.signup}>Sign-up</button> */}
            </div>
        </nav>
    );
}
export default Navbar;