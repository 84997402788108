import React from 'react';
import styles from './ProjectLogo.module.css';

const ProjectLogo = ({ src }) => {
  return (
    <div className={styles.projectLogo}>
      <img src={src} alt="Project Logo" />
    </div>
  );
};

export default ProjectLogo;