import React from 'react';
import { useMsal } from '@azure/msal-react';
import styles from './AzureADButton.module.css';

const AzureADButton = () => {
  const { instance } = useMsal();
  const handleLogin = async () => {
    try {
      await instance.loginPopup();
    } catch (error) {
      console.log('Authentication error:', error);
    }
  };
  return (
    <button className={styles.loginBtn} onClick={handleLogin}>
      <div className={styles.loginWrapper}>
        <div className={styles.login}>Login with Azure AD</div>
      </div>
    </button>
  );
};

export default AzureADButton;