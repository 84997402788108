import React, { useState } from 'react';
import ModalCreate from '../../ModalWindows/ModalCreate';
import Button from './Button';
import styles from './NewProject.module.css';
import Search from './Search';
import { urlNewProject } from '../../../Settings/url';
import { setProjectMap, ProjectOpener, setIsTableVisible } from './ProjectList';
import { setIsTableButtonVisible } from './DownloadDataButton';
import { useMsal } from '@azure/msal-react';


function displayData(data) {
  setProjectMap([data]);
  setIsTableVisible(true);
  setIsTableButtonVisible(true);
  ProjectOpener();
}

function NewProject() {

  const [ModalCreateOpen, setModalCreateOpen] = useState(false);
  const { accounts } = useMsal();
  const userToken = accounts[0].idToken;

  const openModal = (e) => {
    e.preventDefault();
    setModalCreateOpen(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setModalCreateOpen(false);
  };

  const handleAcceptModal = async (projectData) => {
    try {
      const response = await fetch(urlNewProject, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`
        },
        body: JSON.stringify(projectData)
      });

      if (response.ok) {
        const newProject = await response.json();
        displayData(newProject)
      }
    } catch (err) {
      console.error('Error creating project:', err);
    }
    setModalCreateOpen(false);
  };

  return (
    <>
      <div className={styles.gridContainer}>
        <div className={styles.grid1x1}>
          <Button type="primary" children="Create New Project" onClick={openModal} />
        </div>
        <div className={styles.grid2x1}>
          <Search />
        </div>
        <div className={styles.grid2x2}>
          <span id="error-message" className={styles.ErrorStyle}></span>
        </div>
      </div>
      <ModalCreate 
          isOpen={ModalCreateOpen} 
          onClose={closeModal} 
          onAccept={handleAcceptModal} 
      />
    </>
  );
}

export default NewProject;