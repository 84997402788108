import React, {useEffect} from 'react';
import styles from './Project.module.css';
import Navbar from './ProjectComponents/Navbar';
import NewProject from './ProjectComponents/NewProject';
import ProjectList from './ProjectComponents/ProjectList';
import DownloadDataButton from './ProjectComponents/DownloadDataButton';
import ProjectLogo from './ProjectComponents/ProjectLogo';
import ICLogo from './ProjectComponents/ICLogo.png';
import { useMsal } from '@azure/msal-react';
import useRefreshToken from '../Login/refreshToken';

function Project() {
    // const { accounts } = useMsal();
    
    // const checkAndRefreshToken = useRefreshToken();
    // useEffect(() => {
    //     const validateToken = async () => {
    //         const token = await checkAndRefreshToken();
    //         if (!token) {
    //             console.error("Access token is invalid.");
    //         }
    //     };

    //     validateToken();
    // }, [accounts, checkAndRefreshToken]);

    return (
        <>
            <Navbar />
            {/* <div className={styles.myStyleAll}> */}
                <NewProject />
            {/* </div> */}
            <div className={styles.myStyleAll}>
                <ProjectList />
                <DownloadDataButton />
            </div>
            <div>   
                <ProjectLogo src={ICLogo} />
            </div>
        </>
    )
}
export default Project;
