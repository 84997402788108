import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import PropTypes from 'prop-types'
import styles from './ModalCreate.module.css';
import Button from '../Project/ProjectComponents/Button';
import TagsComponent from './ModalTagWindow';
import ErrorMessage from './ModalCreateError';
import { urlSearchProject } from '../../Settings/url';
import { factoryMatch, projectNameMatch, updateFactoryNameInTags, updateProjectNameInTags } from './ModalTagWindow';

export let projectName, setProjectName;
export let factoryName, setFactoryName;
export let errorMessages, setErrorMessages

const ModalCreate = ({ isOpen, onClose, onAccept}) => {
  [errorMessages, setErrorMessages] = useState({});
  [projectName, setProjectName] = useState('');
  [factoryName, setFactoryName] = useState('');
  const [projectStatus] = useState('Active');
  const [projectDestination, setProjectDestination] = useState('');
  const [projectFrequency, setProjectFrequency] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const { accounts } = useMsal();
  const user = accounts.length > 0 ? accounts[0] : null;
  const userToken = accounts[0].idToken;

  const clearFields = () => {
    setProjectName('');
    setProjectDestination('');
    setProjectFrequency('');
    setFactoryName('');
    setTags([]);
    setErrorMessages({});
  } 
  const closeModal = (e) => {
    onClose(e);
    clearFields();
  }

  const projectNameExistence = async (projectName) => {
    try {
      let jsonData = {
        "projectName": projectName
      };
      const response = await fetch(urlSearchProject, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`
        },
        body: JSON.stringify(jsonData)
      });
      const blob = await response.blob();
      
      if (!response.ok) {
        const errorText = await blob.text();
        throw new Error(errorText);
      }
      const haveSameProjectName = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function(event) {
          try {
            const data = event.target.result;
            const jsonData = JSON.parse(data);
            const sameProjectName = jsonData.findIndex((t) => t.PredictionId.slice(0, -7) === projectName);
            resolve(sameProjectName !== -1);
          } catch (err) {
            reject(err);
          }
        };
        reader.readAsText(blob);
      });
      return haveSameProjectName;
    } catch (err) {
      console.error('Error creating project:', err);
    }
  };

  const handleAccept = async (e) => {
    e.preventDefault();
    setIsDisabled(true);
    setIsLoading(true);
    const exist = await projectNameExistence(projectName)
    if (exist)
    {
      const errors = {};
      errors.projectName = 'The project name already exists';
      setErrorMessages(errors);
      return;
    }
    const projectData = {
        "ProjectName": projectName,
        "Destination": projectDestination,
        "Frequency": projectFrequency,
        "Status": projectStatus,
        "Date": new Date().toISOString(),
        "User": user ? user.name : 'Guest',
        "Tags": tags,
        "Create": true
    };
    await onAccept(projectData);
    clearFields();
    setIsDisabled(false);
    setIsLoading(false);
  };

  const handleProjectNameChange = (e) => {
    const newProjectName = e.target.value;
    setProjectName(newProjectName);
    updateProjectNameInTags(newProjectName);
  };

  const handleFactoryNameChange = (e) => {
    const newFactoryName = e.target.value;
    setFactoryName(newFactoryName);
    updateFactoryNameInTags(newFactoryName);
  };

  
  useEffect(() => {
    const isPascalCase = /^[A-Z][a-z]+(?:[A-Z][a-z]+)*$/.test(projectName);
    const isHttpUrl = /^(https?:\/\/)(?!.*:[0-9])([a-zA-Z0-9.-]+)\.([a-zA-Z0-9-]+)(\/[a-zA-Z0-9-_]+)*$/i.test(projectDestination);
    const isFrequency = /^(?:\d+[mdhs])$/i.test(projectFrequency);
    const isValidFactoryName = /^[A-Z]\d+$/.test(factoryName);

    const errors = {};
    if (!projectName) errors.projectName = "Project Name is required.";
    else if (!isPascalCase) errors.projectName = "Project Name must be in PascalCase.";
    if (!projectNameMatch) errors.projectName = "Project Name of the Tags must match the Project Name.";

    if (!projectDestination) errors.projectDestination = "Project Destination is required.";
    else if (!isHttpUrl) errors.projectDestination = "Project Destination must be a valid URL.";

    if (!projectFrequency) errors.projectFrequency = "Frequency is required.";
    else if (!isFrequency) errors.projectFrequency = "Frequency must be in the format '1d', '2h', etc.";

    if (!factoryName) errors.factoryName = "Plant ID is required.";
    else if (!isValidFactoryName) errors.factoryName = "Plant ID must be a single uppercase letter followed by digits.";
    if (!factoryMatch) errors.factoryName = "Plant ID of the Tags must match the Plant ID of the Project.";

    if (tags.length === 0) errors.tags = "At least one tag is required.";


    setErrorMessages(errors);

    setIsDisabled(Object.keys(errors).length > 0);
    // eslint-disable-next-line
  }, [projectName, projectDestination, projectFrequency, factoryName, tags, projectNameMatch, factoryMatch ]);
  
  return (
    <>
    {isOpen && (
    <div className={styles.coverPage}>
        <div className={styles.myStyleModalAll} >
            <div className={styles.myStyleModalText}>
                <table className={styles.tableWidth}>
                    <tr>
                        <th style={{ textAlign: 'left', width: "30%", verticalAlign: "top" }}><span>Project Name</span></th>
                        <input className={styles.project} type="text" value={projectName} onChange={handleProjectNameChange} />
                        <div className={styles.errorContainer}>
                          {errorMessages.projectName ? (
                            <ErrorMessage message={errorMessages.projectName} />
                          ) : (
                            <div className={styles.placeholder}></div>
                          )}
                        </div>
                    </tr>
                    <tr>
                        <th style={{ textAlign: 'left', width: "30%", verticalAlign: "top" }}><span>Project Destination</span></th>
                        <input className={styles.project} type="text" value={projectDestination} onChange={(e) => setProjectDestination(e.target.value)} />
                        <div className={styles.errorContainer}>
                          {errorMessages.projectDestination ? (
                            <ErrorMessage message={errorMessages.projectDestination} />
                          ) : (
                            <div className={styles.placeholder}></div>
                          )}
                        </div>
                    </tr>
                    <tr>
                        <th style={{ textAlign: 'left', width: "30%", verticalAlign: "top" }}><span>Prediction Frequency</span></th>
                        <input className={styles.project} type="text" value={projectFrequency} onChange={(e) => setProjectFrequency(e.target.value)} />
                        <div className={styles.errorContainer}>
                          {errorMessages.projectFrequency ? (
                            <ErrorMessage message={errorMessages.projectFrequency} />
                          ) : (
                            <div className={styles.placeholder}></div>
                          )}
                        </div>
                    </tr>
                    <tr>
                        <th style={{ textAlign: 'left', width: "30%", verticalAlign: "top" }}><span>Plant ID</span></th>
                        <input className={styles.project} type="text" value={factoryName} onChange={handleFactoryNameChange} />
                        <div className={styles.errorContainer}>
                          {errorMessages.factoryName ? (
                            <ErrorMessage message={errorMessages.factoryName} />
                          ) : (
                            <div className={styles.placeholder}></div>
                          )}
                        </div>
                    </tr>
                </table>
            </div>
            <br />
            <div className={styles.tagWindow}>            
                <TagsComponent tags={tags} setTags={setTags} projectName={projectName} factoryName={factoryName} />
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  {errorMessages.tags && <ErrorMessage message={errorMessages.tags} />}
                </div>
            </div>
            <br />
            <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                <Button type="primary" children="Cancel" onClick={closeModal} />
                <div style={{display: 'flex'}}>
                  {isLoading ? <div className={styles.loader}></div> : ''}
                  <Button type={isDisabled ? "secondary" : "primary"} children="Create" onClick={handleAccept} disabled={isDisabled} />
                </div>
            </div>
        </div>
    </div>
    )}
    </>
  );
};

ModalCreate.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired
};

export default ModalCreate;