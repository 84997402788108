import React from 'react';
import styles from './Button2.module.css';

const Button2 = ({ type, children, onClick, disabled }) => {
  const classNames = `${styles.button} ${styles[type]}`;
  return (
    <button className={classNames} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

export default Button2;