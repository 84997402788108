export const myStyleModalAll = {
alignItems: "flex-start",
boxShadow:
    "0px 24px 48px 0px rgba(0, 0, 0, 0.20), 0px 3px 6px 0px rgba(0, 0, 0, 0.10)",
backgroundColor: "var(--white-100, #FFF)",
alignSelf: "center",
display: "flex",
width: "600px",
height: "410px",
paddingLeft: "50px",
paddingRight: "50px",
paddingTop: "20px",
paddingBottom: "30px",
flexDirection: "column",
margin: '10% auto', 
padding: '20px', 
border: '1px solid #888'
};

export const myStyleModalButtonPlace = {
backgroundColor: "var(--white-100, #FFF)",
alignItems: "flex-start",
display: "flex",
width: "600px",
height: "50px",
maxHeight: "100%",
justifyContent: "space-between",
};

export const myStyleModalButton = {
display: "flex",
flexDirection: "column",
position: "relative",
flexShrink: "0",
boxSizing: "border-box",
appearance: "none",
paddingTop: "15px",
paddingBottom: "15px",
paddingLeft: "25px",
paddingRight: "25px",
backgroundColor: "rgba(14, 11, 176, 1)",
color: "white",
borderRadius: "4px",
textAlign: "center",
cursor: "pointer",
}

export const myStyleModalText = {
alignItems: "flex-start",
backgroundColor: "var(--white-100, #FFF)",
display: "flex",
flexDirection: "column",
};
  
export const myStyleThemedText = {
fontSize: "1em",
color: "var(--your-theme-color, #333)",
marginRight: "15px",
flex: "0 0 auto",
};

export const myStyleInputName = {
width: "300px",
flex: "1",
marginRight: "15px",
};

export const myStyleInputDescription = {
width: "300px",
flex: "1",
marginRight: "15px"
};

export const tableStyles = {
  borderCollapse: 'collapse',
  width: '100%',
  textAlign: 'left',
  marginTop: '20px',
};

export const thStyles = {
  borderBottom: '2px solid #484a62',
  border: '1px solid #484a62',
  backgroundColor: '#303142',
  borderRadius: '8px',
  padding: '12px',
  color: '#fff',
};

export const tagList = {
  width: "100%", 
  height: "170px", 
  maxHeight: "170px", 
  overflowY: 'auto'
};

export const tagLine = {
  width: "125px"
};

export const errorContainer = {
  width: "125px"
};
