/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

const errorIconStyles = css`
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #ccc;
  color: darkred;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  margin-right: 5px;
  cursor: pointer;
  position: relative;

  &:hover .errorMessage {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const errorMessageStyles = css`
  display: none;
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #3c3d4d;
  color: rgb(192, 190, 255);
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 50vh;
  z-index: 100;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
`;

const ErrorIcon = ({ error, index }) => (
  <div key={index} css={errorIconStyles}>
    !
    <div css={errorMessageStyles} className="errorMessage">
        {error}
    </div>
  </div>
);

export default ErrorIcon;

