import React, {useState, useEffect} from 'react';
import { thStyles } from './ModalStyles';
import PropTypes from 'prop-types'
import styles from './ModalTagWindow.module.css';
import Button2 from '../Project/ProjectComponents/Button2';
import ErrorIcon from './ModalTagError';

export let newTagId, setNewTagId;
export let newTagName, setNewTagName;
export let tagName, settagName;
export let newPredictionType, setnewPredictionType;
export let newPredictionMin, setnewPredictionMin;
export let newPredictionMax, setnewPredictionMax;
export let factoryMatch;
export let projectNameMatch;
export let itemNameMatch;
export let updateFactoryNameInTags;
export let updateProjectNameInTags;




const TagsComponent = ({tags, setTags, projectName, factoryName}) => {

    [newTagId, setNewTagId] = useState('');
    [newTagName, setNewTagName] = useState('');
    [tagName, settagName] = useState('');
    [newPredictionType, setnewPredictionType] = useState('');
    [newPredictionMin, setnewPredictionMin] = useState('');
    [newPredictionMax, setnewPredictionMax] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    let errors = {};

    const validateForm = () => {
      const isCamelCase = /^[A-Z][a-z]+(?:[A-Z][a-z]+)*$/.test(projectName);
      const isValidFactoryName = /^[A-Z]\d*$/.test(factoryName);
      const isValidTagName = /^[a-zA-Z0-9]+[a-zA-Z0-9_]*(?<!_)$/.test(tagName);
      const isValidnewPredictionType = /^(int|float|boolean)$/.test(newPredictionType);
      const isValidnewPredictionMin = !isNaN(newPredictionMin) && newPredictionMin.trim() !== '';
      const isValidnewPredictionMax = !isNaN(newPredictionMax) && newPredictionMax.trim() !== '' && parseFloat(newPredictionMax) >= parseFloat(newPredictionMin);
            factoryMatch = tags.every(tag => {
        const parts = tag.name.split('.');
        return parts[2] === factoryName;
      });
      projectNameMatch = tags.every(tag => {
        const parts = tag.name.split('.');
        return parts[3] === projectName;
      });
      itemNameMatch = tags.every(tag => {
        const parts = tag.name.split('.');
        return parts[4] !== tagName;
      });
        if (!projectName.trim()) errors.projectName = "Project Name is required.";
        else if (!isCamelCase) errors.projectName = "Project Name must be in camelCase.";
        if (!projectNameMatch) errors.projectName = "Project Name must match the Project Name of the Factory.";
        if (!factoryName.trim()) errors.factoryName = "Plant ID Name is required.";
        else if (!isValidFactoryName) errors.factoryName = "Plant ID must be a single uppercase letter followed by digits.";
        if (!factoryMatch) errors.factoryName = "Plant ID must match the Plant ID of the Factory.";
        if (!tagName.trim()) errors.tagName = "Tag Name is required.";
        else if (!isValidTagName) errors.tagName = "Example: Requared [At least one letter or digit], Optional [More letters, digits or _], Can't end with [_]";
        if (!itemNameMatch) errors.tagName = "Tag Name must not match any other Tag Names in the Project.";
        if (!newPredictionType.trim()) errors.newPredictionType = "Prediction Type is required.";
        else if (!isValidnewPredictionType) errors.newPredictionType = "Prediction Type must be 'int', 'float', or 'boolean'.";
        if (!newPredictionMin.trim()) errors.newPredictionMin = "Prediction Minimum Value is required.";
        else if (!isValidnewPredictionMin) errors.newPredictionMin = "Prediction Minimum Value must be a number.";
        if (!newPredictionMax.trim()) errors.newPredictionMax = "Prediction Maximum Value is required.";
        else if (!isValidnewPredictionMax) errors.newPredictionMax = "Prediction Maximum Value must be a number greater than or equal to Minimum Value.";
        
        
        
        setErrorMessages(errors);
      setIsFormValid(Object.keys(errors).length === 0);
    };

    updateFactoryNameInTags = (newFactoryName) => {
        const updatedTags = tags.map(tag => ({
          ...tag,
          name: tag.name.replace(/CLOUD\.PLC\.\w*\./, `CLOUD.PLC.${newFactoryName}.`)
        }));
        setTags(updatedTags);
      };

      updateProjectNameInTags = (newProjectName) => {
        const updatedTags = tags.map(tag => ({
          ...tag,
          name: tag.name.replace(/CLOUD\.PLC\.(\w*)\.\w*\./, `CLOUD.PLC.$1.${newProjectName}.`)
        }));
        setTags(updatedTags);
      };

    useEffect(() => {
        validateForm();
        // eslint-disable-next-line
      }, [projectName, factoryName, tagName, newPredictionType, newPredictionMin, newPredictionMax]);

      
      const handleAddTag = (e) => {
        e.preventDefault();
        if (!isFormValid) {
            validateForm();
            return;
        }
        if (projectName.trim() !== '' && factoryName.trim() !== '' && tagName.trim() !== '' && newPredictionType.trim() !== '' && newPredictionMin.trim() !== '' && newPredictionMax.trim() !== '') {
            if (isFormValid) {
                newTagName = `CLOUD.PLC.${factoryName}.${projectName}.${tagName}`;
                const newTag = {
                    name: newTagName,
                    type: newPredictionType,
                    min: newPredictionMin,
                    max: newPredictionMax,
                };
                setTags([...tags, newTag]);
                setNewTagName('');
                settagName('');
                setnewPredictionType('');
                setnewPredictionMin('');
                setnewPredictionMax('');
            }
        }
      };

  const handleRemoveTag = (tag, e) => {
    e.preventDefault();
    const newTags = [...tags];
    const nameIndex = newTags.findIndex((t) => t.name === tag.name);
    if (nameIndex !== -1) {
        newTags.splice(nameIndex, 1);
        setTags(newTags);
    }   
  };
  

  return (
    <div>
      <table className={styles.tableStyles}>
            <tr>
                <th style={thStyles}>Name</th>
                <th style={thStyles}>Type</th>
                <th style={thStyles}>Minimum Value</th>
                <th style={thStyles}>Maximum Value</th>
            </tr>
        <br />
        {tags.map((tag) => (
            <tr key={tag.id}>
                <td>
                    <input className={styles.tagB} value={tag.name} readOnly />
                </td>
                <td>
                    <input className={styles.tagB2} value={tag.type} readOnly />
                </td>
                <td>
                    <input className={styles.tagB2} value={tag.min} readOnly />
                </td>
                <td>
                    <input className={styles.tagB2} value={tag.max} readOnly />
                </td>
                <td>
                    <Button2 type="warning" children="-" onClick={(e) => handleRemoveTag(tag, e)} />
                </td>
            </tr>
        ))}
        <tr>
            <td className={styles.tagTextStarter}>
                <span className={styles.tagText}>CLOUD.PLC.</span>
                <input className={styles.tagFactoryName} type="text" value={factoryName} readOnly />
                <span className={styles.tagText}>.</span>
                <input className={styles.tagProjName} type="text" value={projectName} readOnly />
                <span className={styles.tagText}>.</span>
                <input
                className={styles.tagM}
                type="text"
                value={tagName}
                onChange={(e) => settagName(e.target.value)}
                placeholder="Tag Name"
                />
            </td>
            <td>
                <select
                className={styles.tag}
                value={newPredictionType}
                onChange={(e) => setnewPredictionType(e.target.value)}
                placeholder="Prediction Type"
                >
                    <option value="" disabled> Select Prediction Type </option>
                    <option value="int">int</option>
                    <option value="float">float</option>
                    <option value="boolean">boolean</option>
                    {/* Add more options as needed */}
                </select>
            </td>
            <td>
                <input
                className={styles.tag}
                type="text"
                value={newPredictionMin}
                onChange={(e) => setnewPredictionMin(e.target.value)}
                placeholder="Prediction Min value"
                />
            </td>
            <td>
                <input
                className={styles.tag}
                type="text"
                value={newPredictionMax}
                onChange={(e) => setnewPredictionMax(e.target.value)}
                placeholder="Prediction Max value"
                />
            </td>
            <td>
                <form onSubmit={handleAddTag}>
                    <Button2 type="submit" children="+"/>
                </form>
            </td>
        </tr>
      </table>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'fixed', top: '92%', left: '50%', transform: 'translate(-50%, -50%)'}}>
        {Object.values(errorMessages).map((error, index) => (
            <ErrorIcon error={error} index={index} />
        ))}
      </div>
    </div>
  );
};

TagsComponent.propTypes = {
  tags: PropTypes.array,
  setTags: PropTypes.func,
  newTagName: PropTypes.string,
  setNewTagName: PropTypes.func,
  newPredictionType: PropTypes.string,
  setnewPredictionType: PropTypes.func,
  newPredictionMin: PropTypes.string,
  setnewPredictionMin: PropTypes.func,
  newPredictionMax: PropTypes.string,
  setnewPredictionMax: PropTypes.func
};





export default TagsComponent;